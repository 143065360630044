<template>
    <footer>
      <div class="footer px-3">
        <p>
          Copyright ©{{ new Date().getFullYear() }} All rights reserved by MoC | Technical Support by <a href="javascript:"><img src="@/assets/images/ict-division.png" width="40" alt="ict-division"></a>
        </p>
        <p>Developed by <a href="javascript:"><img src="@/assets/images/syntech.png" width="132" alt="Syntech Soludion Ltd."></a></p>
      </div>
    </footer>
</template>
<script>
export default {
  name: 'Footer'
}
</script>
