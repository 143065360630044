<template>
    <b-nav-item-dropdown class="notification-wrapper" right :show="notLoading">
        <template #button-content>
            <div class="position-relative">
                <i class="ri-notification-3-fill"></i>
                <span class="notifi-counter" v-if="component_id === 1">
                    {{ totalNotification }}
                </span>
                 <span class="notifi-counter" v-else-if="component_id === 5">
                    {{ totalNotification }}
                </span>

                <!-- default zero, if any component load before this line -->
                <span class="notifi-counter" v-else>
                    {{ 0 }}
                </span>
            </div>
        </template>
        <p class="user-disc">Notifications
            <span class="notification-counter" v-if="component_id === 1">
                {{ totalNotification }}
            </span>
             <span class="notification-counter" v-else-if="component_id === 5">
                {{ totalNotification }}
            </span>

            <!-- default zero, if any component load before this line -->
            <span class="notifi-counter" v-else>
                    {{ 0 }}
            </span>
        </p>
        <template v-if="component_id">
            <b-dropdown-item v-for="(item, index) in listData" :key="index" @click="notificationSeen(item)">
                <div class="notification-body">
                    <b-overlay v-show="!notLoading">
    <!--                <img class="notification-img" src="../assets/images/user.jpg" alt="img">-->
                        <div class="notification-disc">
                        <p class="notify-content" :title="item.message">{{ item.message.substring(0,50) + '...' }}</p>
                        <small class="date-time">{{ item.created_at | dateFormatTime }}</small>
                        </div>
                    </b-overlay>
                </div>
            </b-dropdown-item>
            </template>
        <b-dropdown-item v-if="component_id" @click="allNotificationList" class="see-more-btn">See More</b-dropdown-item>
    </b-nav-item-dropdown>
</template>
<script>
import RestApi, { commonServiceBaseUrl, teaGardenServiceBaseUrl } from '@/config/api_config'
import { mapGetters } from 'vuex'
export default {
    props: ['component_id'],
    data () {
        return {
            notificationList: [],
            notLoading: false,
            baseComList: {
                1: commonServiceBaseUrl,
                5: teaGardenServiceBaseUrl
            }
        }
    },
    computed: {
        totalNotification () {
            return this.$store.state.totalNotification
        },
        listData () {
            return this.$store.state.notificationList
        },
        currentLocale () {
            return this.$i18n.locale
        },
        ...mapGetters({
            authUser: 'Auth/authUser'
        })
    },
    created () {
        if (this.component_id) {
            this.getNotifications()
        }
    },
    methods: {
        async getNotifications () {
            const baseUrl = this.baseComList[this.component_id]
            await RestApi.getData(baseUrl, '/notifications/received-list').then(response => {
                if (response.data.length > 0) {
                    this.$store.dispatch('setNotificationList', response.data)
                    this.$store.dispatch('setTotalNotification', response.total)
                } else {
                    this.$store.dispatch('setNotificationList', [])
                    this.$store.dispatch('setTotalNotification', 0)
                }
            })
        },
        async notificationSeen (obj) {
            const baseUrl = this.baseComList[this.component_id]
            this.notLoading = true
            await RestApi.putData(baseUrl, `/notifications/update/${obj.id}`).then(response => {
                if (response.success) {
                    this.$store.dispatch('setNotificationList', response.data)
                    let totalNotification = this.$store.state.totalNotification
                    totalNotification = response.total
                    if (totalNotification >= 0) {
                        this.$store.dispatch('setTotalNotification', totalNotification)
                    }
                    this.notLoading = false
                    this.$router.push(obj.url)
                } else {
                    this.$store.dispatch('setNotificationList', [])
                    this.$store.dispatch('setTotalNotification', 0)
                    this.notLoading = false
                }
            })
        },
        allNotificationList () {
            if (this.component_id === 5) {
                if (this.authUser.user_type === 1) {
                    this.$router.push({ name: 'tea_garden_service.configuration.received_notifications' })
                } else {
                    this.$router.push({ name: 'tea_garden_panel.received_notifications' })
                }
            }
        }
    }
}
</script>
