<template>
    <div>
      <b-navbar toggleable="lg" class="fixed-nav">
        <b-navbar-brand  v-if="layout !== 'Home'" href="javascript:" class="d-none d-md-block sidebar-collapse-icon-wrapper" @click="toggleNav()">
          <i class="ri-menu-3-fill"></i>
        </b-navbar-brand>
        <b-navbar-brand v-b-toggle.sidebar-1 v-if="layout !== 'Home'"  href="javascript:" class="d-sm-block d-md-none sidebar-collapse-icon-wrapper">
          <i class="ri-menu-3-fill toggle-bar-icon"></i>
        </b-navbar-brand>
        <b-navbar-brand href="javascript:" class="logo-wrapper">
          <img v-if="layout === 'Home'" src="../assets/images/logo.png" class="mr-2" width="42" alt="">
          <span>{{ $t('globalTrans.project_name') }}  <span class="sub-brand">{{ $t('globalTrans.moc') }}</span></span>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
          <a style="color: white" v-if="manualOne" type="button" class="btn btn-sm btn-success" :href="manualOne" target="_blank">{{ $i18n.locale === 'bn' ? 'ম্যানুয়াল' : 'Manual' }}</a>&nbsp;
          <a style="color: white" v-if="manualTwo" type="button" class="btn btn-sm btn-success" :href="manualTwo" target="_blank">{{ $i18n.locale === 'bn' ? 'ম্যানুয়াল' : 'Manual' }}{{ $n(2) }}</a>&nbsp;
            <LangButton />
            <Notification :component_id="component_id"/>
            <SitePreference />
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <b-sidebar id="sidebar-1" shadow backdrop>
        <div class="sidebar-section w-100 show-mobile d-sm-block d-md-none">
          <Sidebar></Sidebar>
        </div>
      </b-sidebar>
    </div>
</template>
<script>
import { EventBus } from '@/EventBusLayout'
import Sidebar from '@/components/Sidebar.vue'
import LangButton from '@/components/LangButton.vue'
import Notification from '@/components/Notification.vue'
import SitePreference from '@/components/SitePreference.vue'
import RestApi, { authServiceBaseUrl } from '@/config/api_config'

export default {
  Name: 'Navbar',
  props: ['layout', 'component_id'],
  data () {
    return {
      authServiceBaseUrl: authServiceBaseUrl,
      manualOne: '',
      manualTwo: '',
      toggle: false
    }
  },
  components: {
    Sidebar,
    LangButton,
    Notification,
    SitePreference
  },
  created () {
    this.manualOne = null
    this.manualTwo = null
    this.callAllManual()
  },
  methods: {
    async callAllManual () {
        const roleId = this.$store.state.Auth.authUser.user_type === 1 ? (this.$store.state.Auth.authUserRoles?.[0]?.id ?? this.$store.state.Auth.authUser?.id ?? '') : ''
        const orgId = this.$store.state.Auth.authUserRoles?.[0]?.org_id ?? this.$store.state.Auth.authUser?.org_id ?? ''
        if (!roleId || !orgId) {
            this.manualOne = null
            this.manualTwo = null
        }
        try {
            const response = await RestApi.getData(authServiceBaseUrl, '/manual-upload/manual-all')
            if (response && response.success) {
                const manualData = response?.data.filter(
                    (item) =>
                        item.org_id === parseInt(orgId) && item.role_id === parseInt(roleId)
                )
                if (manualData && manualData.length > 0) {
                    this.manualOne = manualData[0].pdf_file
                    this.manualTwo = manualData[0].pdf_file_two
                } else {
                    this.manualOne = null
                    this.manualTwo = null
                }
            } else {
                this.manualOne = null
                this.manualTwo = null
            }
        } catch (error) {
            this.manualOne = null
            this.manualTwo = null
        }
    },
    toggleNav () {
      this.toggle = !this.toggle
      EventBus.$emit('toggleNav', this.toggle)
    }
  }
}
</script>
